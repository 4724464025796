const scipDebtSchedulesReport = {
  label: "SCIP Debt Schedules Report",
  name: "scipDebtSchedulesReport",
  parameters: [
    {
      name: "program",
      label: "Program",
      type: "dbfield",
      db: "scip",
      table: "Program",
      keyField: "ProgramId",
      valueField: "Name",
      sort: {
        Name: "asc",
      },
      multiple: true,
    },
    {
      name: "divider",
      text: `<div style="display: flex; align-items: center; gap: 10px"><hr style="flex-grow: 1">or<hr style="flex-grow: 1"></div>`,
      type: "note",
    },
    {
      name: "district",
      label: "District",
      type: "dbfield",
      db: "scip",
      table: "District",
      keyField: "DistrictId",
      valueField: "DistrictName",
      sort: {
        DistrictName: "asc",
      },
      multiple: true,
    },
  ],
};

export default scipDebtSchedulesReport;
